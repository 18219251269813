<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="text"
    ref="contentRef"
    class="atom-richtext "
    :class="[layout, computedValues.gridStart, { 'is-line-clamp': lineClamp !== false && isCollapsed && showReadMore }]"
  >
    <div class="atom-richtext__content" v-html="textRender" />

    <AtomButton
      v-if="lineClamp !== false && isCollapsed && showReadMore"
      class="atom-richtext__read-more"
      :text="useTranslation('general', 'read_more')"
      @click="toggleLineClamp"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    html: {
        type: String,
        default: '',
    },
    string: {
        type: String,
        default: '',
    },
    isStrapi: {
        type: Boolean,
        default: false,
    },
    layout: {
        type: String,
        default: '',
    },
});

/*
    Computed Data
*/
const storyStore = useStoryStore();

const text = computed(() => {
    if (props.data?.strapi_data_key) {
        const strapiData = storyStore.getConnectedStrapiValue(props.data.strapi_data_key);

        return strapiData ? useMarkdown(strapiData) : '';
    }

    if (!props.isStrapi) {
        const storyblokApi = useStoryblokApi();
        if (props.data?.text) {
            return storyblokApi.richTextResolver.render(props.data.text);
        }
        return props.html.length > 0
            ? props.html
            : `<p>${props.string}</p>`;
    }

    if (!props.string) return '';
    return useMarkdown(props.string);
});

const isCollapsed = ref(true);

const lineClamp = computed(() => {
    if (!props.data?.use_read_more) return false;

    const limit = getStoryblokSetting('general', 'read_more_amount_of_rows');
    return limit;
});

/*
    Methods
*/
const toggleLineClamp = () => {
    isCollapsed.value = !isCollapsed.value;
};

/*
    Replacement Data for DeliveryPopup
*/
const deliveryPopupStore = useDeliveryPopupStore();
const product = computed(() => deliveryPopupStore.getProduct);

const replacements = computed(() => ({
    '{{SWISSMEDIC_CATEGORY}}': product?.value?.swissmedic_category || '',
}));

/*
    Rendered Text
*/
const textRender = computed(() => {
    let string = text.value;

    /* Replace if replacementstring */
    Object.entries(replacements?.value).forEach((entry) => {
        const [placeholder, value] = entry;
        string = string ? string.replaceAll(placeholder, value) : '';
    });

    const escapeReplacements = {
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': '\'',
        '&amp;': '&',
    };

    Object.entries(escapeReplacements).forEach((entry) => {
        const [placeholder, value] = entry;
        if (string.includes(placeholder)) {
            string = string.replaceAll(placeholder, value);
        }
    });

    return string;
});

/*
    Count lines of text
*/
const contentRef = ref(null);
const showReadMore = ref(false);

onMounted(() => {
    const element = contentRef.value;
    if (!element) return 0;

    const lineHeight = 26;
    const height = element.offsetHeight;
    const lines = Math.round(height / lineHeight);

    if (lineClamp.value && lines > lineClamp.value) {
        showReadMore.value = true;
    }

    return true;
});

// Check if the page is Produkte page then is wide
const { currentRoute } = useRouter();
const routeName = currentRoute.value.name;

const computedValues = computed(() => ({
    gridStart: routeName === 'produkte-id' && 'wide',
}));
</script>

<style lang="scss">
.atom-richtext {
    @include component-spacing(20px, 30px);

    @include top-level-only {
        @include default-content-columns;
    }

    &.wide {
        @include default-content-columns(true);
    }

    & + .atom-link {
        @include fluid('margin-top', 10px, 20px);
    }

    & + .block-folder-links {
        @include fluid('margin-top', 30px, 100px);
    }

    & + .block-indications {
        @include fluid('margin-top', 60px, 120px);
    }

    & + .block-quote {
        @include fluid('margin-top', 50px, 100px);
    }

    & + .block-jobs-list {
        @include fluid('margin-top', 30px, 40px);
    }

    & + .atom-heading {
        @include fluid('margin-top', 30px, 40px);
    }

    & + .block-brands-list {
        @include fluid('margin-top', 30px, 100px);
    }

    & + .shop-products-curated {
        @include fluid('margin-top', 30px, 80px);
    }

    & + .block-indications__wrapper {
        @include fluid('margin-top', 30px, 100px);
    }

    & + .block-accordion {
        @include fluid('margin-top', 30px, 74px);
    }

    & + .atom-image {
        @include fluid('margin-top', 20px, 40px);
    }

    & + .block-bundle-spotlight {
        @include fluid('margin-top', 20px, 40px);
    }

    & + .block-author {
        @include fluid('margin-top', 50px, 100px);
    }

    & + .block-event-subscription {
        @include fluid('margin-top', 40px, 60px);
    }

    & + .block-banner-cta {
        @include fluid('margin-top', 50px, 100px);
    }

    & + .shop-products {
        @include fluid('margin-top', 50px, 100px);
    }
}

.atom-richtext__content {
    @include typo-size('p');
    @include typo-font('light');

    position: relative;
    color: $C_GREEN_DARK;
    word-break: break-word;

    a {
        text-decoration: underline;
        transition: color 0.2s;

        &:hover {
            color: $C_PRIMARY;
            transition: color 0.2s;
        }
    }

    ul {
        padding-left: 0;
    }

    li {
        @include fluid('padding-left', 24px, 30px);

        position: relative;
        margin-bottom: 15px;
        list-style: none;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            @include fluid('left', 12px, 8px);
            @include fluid('top', 10px, 13px);

            position: absolute;
            width: 3px;
            height: 3px;
            border-radius: 30px;
            margin: auto;
            background-color: $C_GREEN_DARK;
            content: ' ';
        }
    }

    p {
        @include fluid('margin-bottom', 35px, 40px);
        @include fluid('line-height', 24px, 26px);

        b, strong {
            @include typo-font('bold');
        }

        &:has(b) {
            @include fluid('margin-bottom', 15px, 30px);
        }

        &:last-child {
            margin: 0;
        }
    }

    i {
        @include typo-font('italic');
    }

    h4 {
        @include typo-font('regular');
    }

    .app-cookie-consent & {
        a {
            @include typo-font('bold');
        }
    }

    .is-line-clamp & {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: v-bind(lineClamp);
        text-overflow: ellipsis;

        p {
            display: inline;
        }

        p::after {
            content: ' \A\A';
            white-space: pre;
        }

        &:after {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 75px;
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            content: '';
        }
    }

    & + .block-link-box {
        @include fluid('margin-top', 20px, 60px);
    }
}

.atom-richtext__read-more {
    margin-top: 25px;
}
</style>
